import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Framework } from 'vuetify/types'

import ru from 'vuetify/src/locale/ru'
import en from 'vuetify/src/locale/en'
import settings from './settings'

declare module 'vue/types/vue' {
  export interface Vue {
    $vuetify: Framework
  }
}

Vue.use(Vuetify)

const vuetify = new Vuetify({
  theme: {
    dark: settings.company.companyUISettings.theme.defaultTheme === 'dark',
    options: { customProperties: true },
    themes: {
      light: {
        'primary': settings.company.companyUISettings.theme.light.primary,
        'secondary': settings.company.companyUISettings.theme.light.secondary,
        'accent': settings.company.companyUISettings.theme.light.accent,
        'error': settings.company.companyUISettings.theme.light.error,
        'info': settings.company.companyUISettings.theme.light.info,
        'success': settings.company.companyUISettings.theme.light.success,
        'warning': settings.company.companyUISettings.theme.light.warning,
        'view': settings.company.companyUISettings.theme.light.view,
        'dialog': settings.company.companyUISettings.theme.light.dialog,
        'card': settings.company.companyUISettings.theme.light.card,
        'group': settings.company.companyUISettings.theme.light.group,
        'mainmenu': settings.company.companyUISettings.theme.light.drawer,
        'input-back': settings.company.companyUISettings.theme.light.input,
        'search-back': settings.company.companyUISettings.theme.light.search,
        'button-def': settings.company.companyUISettings.theme.light.button,
        'onbutton': settings.company.companyUISettings.theme.light.buttonText,
        'onbutton-styled': settings.company.companyUISettings.theme.light.buttonStyledText,
        'onbutton-primary': settings.company.companyUISettings.theme.light.buttonPrimaryText,
        'tool-def': settings.company.companyUISettings.theme.light.tool,
        'ontool': settings.company.companyUISettings.theme.light.toolText,
        'ontool-styled': settings.company.companyUISettings.theme.light.toolStyledText,
        'ontool-primary': settings.company.companyUISettings.theme.light.toolPrimaryText,
        'normal': settings.company.companyUISettings.theme.light.text,
        'invert': settings.company.companyUISettings.theme.light.inverted,
        'caption': settings.company.companyUISettings.theme.light.caption,
        'inactive': settings.company.companyUISettings.theme.light.inactive,
        'disabled': settings.company.companyUISettings.theme.light.disabled,
        'divider': settings.company.companyUISettings.theme.light.divider,
        'outline': settings.company.companyUISettings.theme.light.outline,
        'banner': settings.company.companyUISettings.theme.light.banner,
        'onbanner': settings.company.companyUISettings.theme.light.bannerText,
        'toolbar': settings.company.companyUISettings.theme.light.toolbar,
        'ontoolbar': settings.company.companyUISettings.theme.light.toolbarText,
        'footer': settings.company.companyUISettings.theme.light.footer,
        'onfooter': settings.company.companyUISettings.theme.light.footerText,
      },

      dark: {
        'primary': settings.company.companyUISettings.theme.dark.primary,
        'secondary': settings.company.companyUISettings.theme.dark.secondary,
        'accent': settings.company.companyUISettings.theme.dark.accent,
        'error': settings.company.companyUISettings.theme.dark.error,
        'info': settings.company.companyUISettings.theme.dark.info,
        'success': settings.company.companyUISettings.theme.dark.success,
        'warning': settings.company.companyUISettings.theme.dark.warning,
        'view': settings.company.companyUISettings.theme.dark.view,
        'dialog': settings.company.companyUISettings.theme.dark.dialog,
        'card': settings.company.companyUISettings.theme.dark.card,
        'group': settings.company.companyUISettings.theme.dark.group,
        'input-back': settings.company.companyUISettings.theme.dark.input,
        'search-back': settings.company.companyUISettings.theme.dark.search,
        'button-def': settings.company.companyUISettings.theme.dark.button,
        'onbutton': settings.company.companyUISettings.theme.dark.buttonText,
        'onbutton-styled': settings.company.companyUISettings.theme.dark.buttonStyledText,
        'onbutton-primary': settings.company.companyUISettings.theme.dark.buttonPrimaryText,
        'tool-def': settings.company.companyUISettings.theme.dark.tool,
        'ontool': settings.company.companyUISettings.theme.dark.toolText,
        'ontool-styled': settings.company.companyUISettings.theme.dark.toolStyledText,
        'ontool-primary': settings.company.companyUISettings.theme.dark.toolPrimaryText,
        'normal': settings.company.companyUISettings.theme.dark.text,
        'invert': settings.company.companyUISettings.theme.dark.inverted,
        'caption': settings.company.companyUISettings.theme.dark.caption,
        'inactive': settings.company.companyUISettings.theme.dark.inactive,
        'disabled': settings.company.companyUISettings.theme.dark.disabled,
        'divider': settings.company.companyUISettings.theme.dark.divider,
        'outline': settings.company.companyUISettings.theme.dark.outline,
        'banner': settings.company.companyUISettings.theme.dark.banner,
        'onbanner': settings.company.companyUISettings.theme.dark.bannerText,
        'toolbar': settings.company.companyUISettings.theme.dark.toolbar,
        'ontoolbar': settings.company.companyUISettings.theme.dark.toolbarText,
        'footer': settings.company.companyUISettings.theme.dark.footer,
        'onfooter': settings.company.companyUISettings.theme.dark.footerText,
      },
    },
  },
  icons: {
    iconfont: 'fa',
    values: {
      'copy-data': 'M10.9336 18H4.21875C2.66789 18 1.40625 16.7384 1.40625 15.1875V5.66016C1.40625 4.1093 2.66789 2.84766 4.21875 2.84766H10.9336C12.4845 2.84766 13.7461 4.1093 13.7461 5.66016V15.1875C13.7461 16.7384 12.4845 18 10.9336 18ZM4.21875 4.25391C3.44339 4.25391 2.8125 4.8848 2.8125 5.66016V15.1875C2.8125 15.9629 3.44339 16.5938 4.21875 16.5938H10.9336C11.709 16.5938 12.3398 15.9629 12.3398 15.1875V5.66016C12.3398 4.8848 11.709 4.25391 10.9336 4.25391H4.21875ZM16.5586 13.4297V2.8125C16.5586 1.26164 15.297 0 13.7461 0H5.94141C5.55304 0 5.23828 0.314758 5.23828 0.703125C5.23828 1.09149 5.55304 1.40625 5.94141 1.40625H13.7461C14.5215 1.40625 15.1523 2.03714 15.1523 2.8125V13.4297C15.1523 13.8181 15.4671 14.1328 15.8555 14.1328C16.2438 14.1328 16.5586 13.8181 16.5586 13.4297Z',
    },
  },
  lang: {
    locales: { ru, en },
  },
})

export default vuetify;
